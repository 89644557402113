<template>
  <div class="all">
    <Header/>
    <div class="dailipart2">
      <!-- <div class="dailipart2-text1">
        集团产业
        <div style="display: inline-block">·</div>
        电商板块
      </div> -->
    </div>
    <div class="dailipart3">
      <div>当前位置：首页 > 代理记账</div>
    </div>
    <div class="dailipartt6">
      <div class="dailipartt6-center">
        <div class="dailipartt6-center-text">金牌服务保障<p style="text-align: center; display: block;font-size: 16px;"></p></div>
        <div
          class="dailipartt6-center-div1"
          v-for="item in ObjectList"
          :key="item.img"
        >
          <img :src="item.img" class="dailipartt6-center-div1-img" />
          <div class="dailipartt6-center-div1-text1">{{ item.text1 }}</div>
          <div class="dailipartt6-center-div1-text2">{{ item.text2 }}</div>
        </div>
      </div>
    </div>
      
    <div class="about">
      <div class="dailipart4">
        <div class="dailipart4-text">卓翔代理记账服务内容</div>
        <!-- <div class="dailipart4-div1">
          <div class="dailipart4-div1-div1">
            <div class="dailipart4-div1-div1-text">
              随着信息时代的到来和电商行业的深度调整、发展，传统行业逐渐向新兴行业转变，更多的行业参与到越来越大众化的电商行业中来。打造前程电商板块契合经济发展方向，以技术创新为驱动，以数字化管理为保障，汇集网店运营、跨境电商、新媒体营销、短视频直播、校园新零售等多样化的运营模式，与河南省多所院校合作，联合建立校园电商实训中心和产教融合电商实训孵化基地，打造高附加值电商新生态产业链。
            </div>
          </div>
          <div class="dailipart4-div1-div2">
            <img src="../assets/homepage/位图24.png" />
          </div>
        </div> -->
      </div>
      <div class="dailipart5">
        <img src="../assets/homepage/zxcw/jcfw.png" />
        <div class="dailipart5-div1">
          <div class="dailipart5-div1-text1">基础服务</div>
          <div class="dailipart5-div1-text2">
            1.初始建账 (建立会计帐套)
            会计人员按照需用的各种账簿的格式要求，预备各种账页装订成册。
            2.每月纳税申报
            专属会计每月定时填制纳税申报表，定时向税务机关申报纳税。
            3.每月代为打印和装订用户凭证、报表及账本
            根据每月收到的票据，有序编制记账凭证。
            4.税务报道
            取得执照后30日内必须办理税务登记，以免工商局吊销执照。
            5.每月出具财务报表
            每月做账目汇总，包括资产负债表、损益表等，让账目归纳有序。
            6.每月代理记账 
            建立科目余额表，同步登记帐簿等，做到账目出入清晰明了 
          </div>
        </div>
      </div>
      <div class="dailipart6">
        <div class="dailipart6-div1">
          <div class="dailipart6-div1-text1">增值服务</div>
          <div class="dailipart6-div1-text2">
            1.税务年报
            了解企业利润实现和分配、生产经营基本情况，利于让你做规划调整。
            2.指导客户抄税清卡
            提醒并指导抄税清卡，防止税盘被锁死，造成报税麻烦。
            3.提供公司常用表格模板
            提供实用的各类财务和管理表格，帮助提升企业管理效率。
            4.手机实时查账
            手机实时监控，无需翻阅账本，及时高效了解账务情况。
            5.代为保管证(1)
            提供贴心保管凭证服务，让企业账日归纳有序。
            6.免费财税咨询建议
            提供日常税务咨询，轻松为你解决各类税务难题。
          </div>
        </div>
        <img src="../assets/homepage/zxcw/zzfw.png" class="dailipart6-img" />
      </div>
    </div>
    <Bottom2></Bottom2>
    <Nav></Nav>
  </div>
</template> 
<style scoped>
@import url("../assets/style9.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import Bottom2 from "../components/nav1/bottomnav2.vue";
export default {
  components: {
    Header,
    Nav,
    Bottom2
  },
  data() {
      return{
          ObjectList: [
            {
              img: require("../assets/homepage/zxcw/nzk1.png"),
              text1: "0出错",
              text2:
                "线上随时查账记账出错全额赔付",
            },
            {
              img: require("../assets/homepage/zxcw/nzk2.png"),
              text1: "无延期无漏办",
              text2:
                "设置超前自动记账提示系统记账无延期",
            },
            {
              img: require("../assets/homepage/zxcw/nzk3.png"),
              text1: "解决财务危机",
              text2:
                "提供优化成本配置、合规避税方案解决企业经营中财务危机",
            },
            {
              img: require("../assets/homepage/zxcw/nzk4.png"),
              text1: "专人代账 高效省心",
              text2:
                "风险预控，一条龙服务承诺出错全额赔付",
            },
            {
              img: require("../assets/homepage/zxcw/nzk5.png"),
              text1: "安全监管",
              text2:
                "行业多年资深服务人员一对一跟踪服务对数据库风险操作行为进行记录与告警",
            },
            {
              img: require("../assets/homepage/zxcw/nzk6.png"),
              text1: "信息保密",
              text2:
                "设置重重加密系统严保个人信息资料零外泄",
            },
          ]
      }
  }
};
</script>