<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="shuiwupart2">
        <!-- <div class="shuiwupart2-text1">集团新闻</div>
        <div class="shuiwupart2-text2">更多最新精彩资讯，感受企业魅力</div> -->
      </div>
      <div class="shuiwupart3">
        <div>当前位置：首页 > 税务筹划</div>
      </div>
      <!-- <div class="shuiwupart4">
        <div class="shuiwupart4-div1"><p>集团新闻</p></div>
        <div class="shuiwupart4-div2"><p>媒体报道</p></div>
        <div class="shuiwupart4-div3"><p>行业资讯</p></div>
      </div> -->
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/wszb.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">微商、直播类</div>
          <div class="shuiwupart5-text-div2">可降低税负70%-90%不用缩减推广费用靠税筹也能控制大头成本...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/smfw.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">商贸物流类</div>
          <div class="shuiwupart5-text-div2">可降低税负30%-50%合理税务筹划能带动资金高效流转解决资金短缺，资金缺口难题...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/jrfw.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">金融服务类</div>
          <div class="shuiwupart5-text-div2">可降低税负80%-90%获客成本越来越高税收少一点就能多省出些获客预...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div>
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/sjfw.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">设计服务类</div>
          <div class="shuiwupart5-text-div2">可降低税负80%-90%企业人力成本逐年增大，成本负担大通过税筹控制各类开支成本可降低到10%...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/jsfw.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">技术服务类</div>
          <div class="shuiwupart5-text-div2">可降低税负80%-90%技术人员工资越来越高通过合理避税能少的不止一点点...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/zxfw.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">咨询服务类</div>
          <div class="shuiwupart5-text-div2">可降低税负80%-90%企业竞争压力大，利润小情况下合理税务筹划能将税后收入提高到90%%6...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart5">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/zxcw/jzaz.png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart5-text-div1">建筑及安装类</div>
          <div class="shuiwupart5-text-div2">可降低税负50%-60%成本控制除了要控制材料采购外税务筹划更是大头...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart5-text-div-div1"> </div> 
           <div class="shuiwupart5-text-div-div2">获取节税方案></div>
            </div>
        </div>
      </div>
      <!-- <div class="shuiwupart6">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/位图(7).png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="shuiwupart5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart6-text-div-div1"> 2021/6/05</div> 
           <div class="shuiwupart5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart6">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/位图(8).png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="shuiwupart5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart6-text-div-div1"> 2021/6/05</div> 
           <div class="shuiwupart5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div>
      <div class="shuiwupart6">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/位图(9).png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="shuiwupart5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart6-text-div-div1"> 2021/6/05</div> 
           <div class="shuiwupart5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div> -->
      <!-- <div class="shuiwupart6">
        <div class="shuiwupart5-img">
          <img src="../../src/assets/homepage/位图(10).png" />
        </div>
        <div class="shuiwupart5-text">
          <div class="shuiwupart6-text-div1">用系统思维下好“职业教育一盘大棋”</div>
          <div class="shuiwupart5-text-div2">党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...</div>
          <div class="shuiwupart5-text-div">
           <div class="shuiwupart6-text-div-div1"> 2021/6/05</div> 
           <div class="shuiwupart5-text-div-div2">查看更多></div>
            </div>
        </div>
      </div> -->
      <Bottom2></Bottom2>
    <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style3.css");
</style>
<script>
import Nav from '../components/nav1/bottomnav.vue'
import Header from '../components/nav1/index2.vue';
import Bottom2 from "../components/nav1/bottomnav2.vue";
export default {
   components :{
     Header,
     Bottom2,
     Nav
   }
}
</script>