<template>
  <div class="all">
    <Header/>
    <div class="zizhipart2">
      <!-- <div class="zizhipart2-text1">
        集团产业
        <div style="display: inline-block">·</div>
        职教板块
      </div> -->
    </div>
    <div class="zizhipart3">
      <div>当前位置：首页 > 资质办理</div>
    </div>
    <!-- <div class="zizhipart4">
      <div class="zizhipart4-text1">职教板块</div>
      <div class="zizhipart4-div1">
        <div class="zizhipart4-div1-text1">
          党的十九大提出，“深化产教融合、协同育人、校企合作”的要求，“支持和规范社会力量兴办教育”。集团公司教育板块契合国家政策，秉承“校企合作、产教融合，依托学校、独立发展”的职业教育发展模式，积极发挥企业办学的主体作用，促进人才培养供给侧和产业需求侧结构要素全方位融合。同时，围绕市场需求、产业需求等办专业，促进人才的良性发展，提高人力资本价值；
          开展引企入教、引教入企、产教融合，深化产教融合创新，积极探索发展校企共建专业、共建二级学院等合作模式，打造产教融合发展创新生态圈。
        </div>
        <div class="zizhipart4-div1-img">
          <img src="../assets/homepage/位图28.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="zizhipart5">
      <div class="zizhipart5-center">
        <div class="zizhipart5-center-text1">在线申请 四步即可领证</div>
        <div class="zizhipart5-center-text2">
          全程代理，办理进度随时查，通过率高，拿证更快
        </div>
        <img src="../assets/homepage/zxcw/banzhenbz.png" />
      </div>
    </div>
    <div class="zizhipart6">
      <div class="zizhipart6-center">
        <div class="zizhipart6-center-text">我们的优势<p style="text-align: center; display: block;font-size: 16px;">专业团队全程代办 权威认证让您省心更安心</p></div>
        
        <div
          class="zizhipart6-center-div1"
          v-for="item in ObjectList"
          :key="item.img"
        >
          <img :src="item.img" class="zizhipart6-center-div1-img" />
          <div class="zizhipart6-center-div1-text1">{{ item.text1 }}</div>
          <div class="zizhipart6-center-div1-text2">{{ item.text2 }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="zizhipart7">
      <div class="zizhipart7-text">教学产品</div>
      <div class="zizhipart7-div" v-for="item in imgList" :key="item.img">
        <img :src="item.img" class="zizhipart7-div-img" />
        <div class="zizhipart7-div-text1">{{ item.text1 }}</div>
        <div class="zizhipart7-div-text2">{{ item.text2 }}</div>
      </div>
    </div> -->
    <Bottom2></Bottom2>
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style10.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import Bottom2 from "../components/nav1/bottomnav2.vue";
export default {
  components: {
    Header,
    Nav,
    Bottom2
  },
  data() {
    return {
      ObjectList: [
        {
          img: require("../assets/homepage/zxcw/nzk1.png"),
          text1: "拿证快",
          text2:
            "熟知行业政策法规和业务流程极速高效办理，高效过审拿证快人一步",
        },
        {
          img: require("../assets/homepage/zxcw/nzk2.png"),
          text1: "收费低",
          text2:
            "收费透明合理 所有项目均签订合同协议 无隐形收费",
        },
        {
          img: require("../assets/homepage/zxcw/nzk3.png"),
          text1: "服务好",
          text2:
            "专业顾问1对1跟进 办理进度及时反馈 业务疑问随时解答",
        },
        {
          img: require("../assets/homepage/zxcw/nzk4.png"),
          text1: "服务全",
          text2:
            "业务全面 多个城市均有分公司 及时快速解决您的需求",
        },
        {
          img: require("../assets/homepage/zxcw/nzk5.png"),
          text1: "保密严",
          text2:
            "设置重重加密系统 严保个人信息资料，零外泄",
        },
        {
          img: require("../assets/homepage/zxcw/nzk6.png"),
          text1: "保障高",
          text2:
            "12年行业沉淀，30000+客户好评认可 敢承诺不成功不收费",
        },
      ],
      imgList: [
        {
          img: require("../assets/homepage/组件7.png"),
          text1: "大数据技术",
          text2:
            "培养大数据分析挖掘与处理、移动开发与架构、云计算等高级专业大数据技术人才",
        },
        {
          img: require("../assets/homepage/组件8.png"),
          text1: "软件技术",
          text2:
            "培养从事网站开发员、信息化管理员、软件开发、软件维护等工作的应用型专门人才",
        },
        {
          img: require("../assets/homepage/组件9.png"),
          text1: "云计算技术应用",
          text2:
            "培养从事云计算技术支持、云计算运维工程师创新创业型技术技能拔尖人才",
        },
        {
          img: require("../assets/homepage/编组 22.png"),
          text1: "电子商务",
          text2:
            "培养具有较强的电子商务应用能力，从事现代电子商务运作与管理的高素质应用工程师型人才",
        },
        {
          img: require("../assets/homepage/编组 23.png"),
          text1: "网络营销与直播电商",
          text2: "培养“懂营销，会策划，善实操，精技术”的高端复合型技术人才",
        },
        {
          img: require("../assets/homepage/编组 26.png"),
          text1: "数字媒体技术",
          text2:
            "培养影视与动画设计制作、虚拟现实与游戏开发、人机交互系统研发等工作的交叉性复合型人才",
        },
        {
          img: require("../assets/homepage/编组 27.png"),
          text1: "人工智能技术应用",
          text2:
            "培养爬虫工程师、运维工程师、实施工程师等创新创业型技术技能拔尖人才",
        },
        {
          img: require("../assets/homepage/编组 29.png"),
          text1: "移动应用开发",
          text2:
            "培养互联网开发和企业网站开发领域的高素质劳动者和专业技术技能人才",
        },
        {
          img: require("../assets/homepage/编组 31.png"),
          text1: "婴幼儿托育服务与管理",
          text2:
            "培养较扎实的专业技能、活动设计和组织能力、人际交往能力等全方位婴幼儿领域的专业人才",
        },
        {
          img: require("../assets/homepage/椭圆形.png"),
          text1: "早期教育",
          text2:
            "培养从事儿童教育管理、服务及学前儿童教学活动等工作的教育工作者",
        },
        {
          img: require("../assets/homepage/椭圆形(1).png"),
          text1: "计算机应用技术",
          text2:
            "培养具备计算机专业知识和较强的计算机办公自动化、数据库等常用软件应用能力",
        },
        {
          img: require("../assets/homepage/椭圆形(6).png"),
          text1: "商务数据分析与应用",
          text2: "培养具有“懂业务、懂管理、懂分析、懂工具、懂设计”的复合型人才",
        },
        {
          img: require("../assets/homepage/椭圆形(3).png"),
          text1: "网络直播与运营",
          text2: "培养能够将互联网直播技术和传统媒体技能相结合的新型互联网人才",
        },
        {
          img: require("../assets/homepage/椭圆形(2).png"),
          text1: "物联网应用技术",
          text2:
            "培养有基础知识及专业技能，并具有较强综合职业能力的高素质劳动者和技能型人才",
        },
        {
          img: require("../assets/homepage/椭圆形(4).png"),
          text1: "智能互联网络技术",
          text2:
            "培养从事各类电子与信息系统、智能信息领域科学研究、开发及应用的复合型高级科技人才",
        },
        {
          img: require("../assets/homepage/椭圆形(5).png"),
          text1: "全媒体广告策划与营销",
          text2:
            "培养广告活动整体策划与营销推广综合能力，策划、营销、执行等工作的高素质技术技能人才",
        },
        {
          img: require("../assets/homepage/椭圆形(10).png"),
          text1: "融媒体技术与运营",
          text2:
            "培养能独立生产融媒体内容，提笔能写、举机能拍、对筒能讲的全媒体人才能力",
        },
        {
          img: require("../assets/homepage/椭圆形(8).png"),
          text1: "工业互联网技术",
          text2:
            "培养从事工业控制计算机选型、安装、应用开发的高级技术应用性专门人才",
        },
        {
          img: require("../assets/homepage/椭圆形(7).png"),
          text1: "医学营养",
          text2:
            "培养从事临床营养、公共营养、健康管理与营养咨询等工作的高素质实用型健康管理专门人才",
        },
        {
          img: require("../assets/homepage/椭圆形(9).png"),
          text1: "健康管理",
          text2:
            "培养从事健康信息检测、健康咨询、健康指导等工作的高素质实用型健康管理专门人才",
        },
      ],
    };
  },
};
</script>