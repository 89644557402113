<template>
  <div class="all">
   <Header></Header>
    <div class="zhucepart2">
      <!-- <div class="zhucepart2-text1">
        集团产业
        <div style="display: inline-block">.</div>
        科技板块
      </div> -->
      <!-- <div class="zhucepart2-text2">欢迎加入我们</div> -->
    </div>
    <div class="zhucepart3">
      <div>当前位置：首页 > 注册商标</div>
    </div>
    <!-- <div class="zhucepart4">
      <div class="zhucepart4-text">科技板块</div>
      <img src="../assets/homepage/位图23.png" class="zhucepart4-img" />
      <div class="zhucepart4-div2">
        <div class="zhucepart4-div2-text1">
          以科技为引擎，以创新为动力，依托云计算、大数据、人工智能等前沿技术，携手华为、阿里、H3C、浪潮、锐捷等IT行业巨头，为智慧城市、智慧校园的建设和运营提供软硬件一体化解决方案，致力于成为“智慧城市、智慧校园”的践行者。
        </div>
        <div class="zhucepart4-div2-text2">
          集团公司科技板块契合国家数字化经济发展方向，积极探索，不断拓展，联合华为、阿里等国际一流信息化企业，围绕智慧教育、智慧城市等智慧化建设，提供全面的解决方案。深耕教育、政府行业等领域，专注于教育教学产品研发、智慧校园、人脸识别、软件产品研发、ICT实训室、智慧城市、智慧医疗、人才外包、云服务综合提供商等方向，致力于打造综合性智慧化服务平台，为客户提供高效、便捷、个性化的智慧环境。<br />
          紧跟国家信息化发展趋势，依托云计算、大数据、人工智能、移动互联、物联网等新型技术，结合不同的应用场景需求，为府、教育、交通、医疗、金融等行业提供个性化的解决方案，致力于将最新的产品和最先进的技术应用到各行各业的数字化转型中。携手行业合作伙伴，共同服务于各行各业的数字化发展，助力智慧建设。例如，为高校建设“环境智慧化、教学智慧化、学习智化、管理智慧化、生活智慧化“为核心的智慧校园，提供实验室建设、人才培养、课程建设、师资培训、产业学院建设等一系列端到端全方位的教学辅助服务等。
        </div>
      </div>
    </div> -->
    <div class="zhucepart5">
      <div class="zhucepart5-center">
        <div class="zhucepart5-center-text">商标服务范围</div>
        <div class="zhucepart5-div1">
          <img src="../assets/homepage/zxcw/f1.png" class="zhucepart5-div1-img" />
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">商标变更</div>
            <div class="zhucepart5-div1-div-text2">
              专人一对一专办快速变更，快速拿证
            </div>
          </div>
        </div>
        <div class="zhucepart5-div2">
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">商标转让</div>
            <div class="zhucepart5-div1-div-text2">
              转让无后续风险
            </div>
          </div>
          <img src="../assets/homepage/zxcw/f2.png" class="zhucepart5-div1-img" />
        </div>
        <div class="zhucepart5-div2">
          <img src="../assets/homepage/zxcw/f4.png" class="zhucepart5-div1-img" />
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">商标宽展</div>
            <div class="zhucepart5-div1-div-text2">
              商标过期半年以内申请宽展  
            </div>
          </div>
        </div>
        <div class="zhucepart5-div2">
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">商标许可备案</div>
            <div class="zhucepart5-div1-div-text2">
              授权他人使用商标必须提出申请
            </div>
          </div>
          <img src="../assets/homepage/zxcw/f5.png" class="zhucepart5-div1-img" />
        </div>
        <div class="zhucepart5-div2">
          <img src="../assets/homepage/zxcw/f4.png" class="zhucepart5-div1-img" />
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">商标续展</div>
            <div class="zhucepart5-div1-div-text2">
              必须提前12个月申请 
            </div>
          </div>
        </div>
        <!-- <div class="zhucepart5-div2">
          <div class="zhucepart5-div1-div">
            <div class="zhucepart5-div1-div-text1">智慧校园解决方案</div>
            <div class="zhucepart5-div3"><p>智慧班牌</p></div>
            <div class="zhucepart5-div4"><p>智慧教室</p></div>
            <div class="zhucepart5-div4"><p>校园管理平台</p></div>
            <div class="zhucepart5-div5"><p>无线校园</p></div>
            <div class="zhucepart5-div4"><p>校园安防</p></div>
          </div>
          <img src="../assets/homepage/zxcw/f4.png" class="zhucepart5-div1-img" />
        </div> -->
      </div>
      <div class="zhucepart6">
        <div class="zhucepart6-center">
          <div class="zhucepart6-center-text1">为什么要注册商标</div>
          <div class="zhucepart6-center-text2">
            &nbsp;&nbsp;&nbsp;&nbsp;智慧城市是把新一代信息技术充分运用在城市中各行各业基于知识社会下一代创新（创新2.0）的城市信息化高级形态，实现信息化、工业化与城镇化深度融合，有助于缓解“大城市病”，提高城镇化质量，实现精细化和动态管理，并提升城市管理成效和改善市民生活质量。
          </div>
          <div class="zhucepart6-div2">
            <div class="zhucepart6-div2-div1">
              <img src="../assets/homepage/zxcw/sb1.png" />
              <div class="zhucepart6-div2-div1-text1">保护自身品牌</div>
              <div class="zhucepart6-div2-div1-text2">
                避免商标被他人恶意抢注，保护企业自身品牌的合法权益，遇他人仿冒可通过法律维权，获得经济赔偿。
              </div>
            </div>
            <div class="zhucepart6-div2-div2">
              <img src="../assets/homepage/zxcw/sb2.png" />
              <div class="zhucepart6-div2-div1-text1">避免商标侵权</div>
              <div class="zhucepart6-div2-div1-text2">
                避免被他人抢先注册，造成自己的无意侵权，支付巨额赔偿费
              </div>
            </div>
            <div class="zhucepart6-div2-div3">
              <img src="../assets/homepage/zxcw/sb3.png" />
              <div class="zhucepart6-div2-div1-text1">市场拓展必备</div>
              <div class="zhucepart6-div2-div1-text2">
                是进驻商场、超市、天京东等电商平台的必备条件，是做广告宣传的必须证明文件。
              </div>
            </div>
            <div class="zhucepart6-div2-div3 pr1">
              <img src="../assets/homepage/zxcw/sb4.png" />
              <div class="zhucepart6-div2-div1-text1">提高商品价值</div>
              <div class="zhucepart6-div2-div1-text2">
                商标是区别于同类商品的重要标走有利于企业快速树立品牌形象，获取超额收益。
              </div>
            </div>
            <div class="zhucepart6-div2-div3">
              <img src="../assets/homepage/zxcw/sb5.png" />
              <div class="zhucepart6-div2-div1-text1">企业的无形资产</div>
              <div class="zhucepart6-div2-div1-text2">
                商标是企业重要的无形资产，可自由在市场进行转让、许可、抵押等。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="zhucepart7">
        <div class="zhucepart7-text1">华为云</div>
        <div class="zhucepart7-text2">
          携手华为专业、安全、可信的云计算产品，全球领先的研发创新能力，满足更广泛业务需求的行业解决方案，建设和谐共赢的云生态系统。
        </div>
        <div class="zhucepart7-div">
          <div class="zhucepart7-div1">
            <div>
              <div class="zhucepart7-div1-text1">云计算</div>
              <div class="zhucepart7-div1-div1">
                <div style="display: inline-block; vertical-align: top">
                  弹性云服务器
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  云容器引
                </div>
                <div class="zhucepart7-div1-div2">
                  <div style="display: inline-block; vertical-align: top">
                    弹性伸缩服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    镜像服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    专属云
                  </div>
                </div>
              </div>
            </div>
            <div class="zhucepart7-div1-div3">
              <div class="zhucepart7-div1-text1">云储存</div>
              <div class="zhucepart7-div1-div1">
                <div style="display: inline-block; vertical-align: top">
                  云硬盘
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  对象储存服务
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 18px;
                  "
                >
                  云硬盘备份
                </div>
                <div class="zhucepart7-div1-div2">
                  <div style="display: inline-block; vertical-align: top">
                    云服务器备份
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    数据快递服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 18px;
                    "
                  >
                    弹性文件服务
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zhucepart7-div2">
            <img src="../assets/homepage/编组 11.png" />
          </div>
          <div class="zhucepart7-div3">
            <div>
              <div class="zhucepart7-div3-text1">云安全</div>
              <div class="zhucepart7-div3-div1">
                <div style="display: inline-block; vertical-align: top">
                  Anti-DDos流量清洗
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  漏洞扫描服务
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  安全指数服务
                </div>
              </div>
              <div class="zhucepart7-div3-div1">
                <div style="display: inline-block; vertical-align: top">
                  企业主机安全
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  WEB应用防火墙
                </div>
                <div
                  style="
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 16px;
                  "
                >
                  数据库安全服务
                </div>
              </div>
            </div>
            <div>
              <div class="zhucepart7-div3-div2">
                <div class="zhucepart7-div3-text1">云数据库</div>
                <div class="zhucepart7-div3-div1">
                  <div style="display: inline-block; vertical-align: top">
                    云数据库MySQL
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 16px;
                    "
                  >
                    分部式缓存服务
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 16px;
                    "
                  >
                    文档数据库服务
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zhucepart7-div4">
            <div class="zhucepart7-div4-text1">云网络</div>
            <div class="zhucepart7-div4-div">
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 25px;
                "
              >
                虚拟私有云
              </div>
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 16px;
                "
              >
                弹性负载均衡
              </div>
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-left: 16px;
                "
              >
                虚拟专用网络
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <Bottom2></Bottom2>
      <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style8.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
import Bottom2 from "../components/nav1/bottomnav2.vue";
export default {
  components: {
    Header,
    Nav,
    Bottom2
  }
};
</script>