<template>
  <div class="div1">
    <div class="div2">
      <div class="div3">
        <div class="flex1">
          <div class="div4">
            <span class="span1"> 行政运营</span>
            <span class="span2">截止时间：2020-10-27</span>
          </div>
          <div class="div5">
            <span class="span3">学历要求：大专以上</span>
            <span class="span4">工作经验：2年以上</span>
            <div class="div7">
              <p class="div7_wenzi">申请岗位</p>
            </div>
          </div>
          <div class="div6">
            <span class="span5">招聘人数：3人</span>
            <span class="span6">工作地点：郑州高新区科学大道100号</span>
          </div>
          <div class="div8">
            <p class="p1">岗位职责</p>
            <p class="p2">
              1.负责公司日常行政管理、人力资源、后勤管理和接待工作；
            </p>
            <p class="p2">2.负责公司各类公文起草，更新公司网页新闻等；</p>
            <p class="p2">
              3.负责公司办公用品采购、固定资产管理、会议管理、文印及外联工作等；
            </p>
            <p class="p2">4.负责公司网站建设，维护办公设施设备及网络。</p>
          </div>
          <div class="div8">
            <p class="p1">岗位职责</p>
            <p class="p2">
              1.负责公司日常行政管理、人力资源、后勤管理和接待工作；
            </p>
            <p class="p2">2.负责公司各类公文起草，更新公司网页新闻等；</p>
            <p class="p2">
              3.负责公司办公用品采购、固定资产管理、会议管理、文印及外联工作等；
            </p>
            <p class="p2">4.负责公司网站建设，维护办公设施设备及网络。</p>
          </div>
        </div>
        <div class="div9">
          <div class="div9_wenxi">
            <!-- <img src="./img/公共-更多2@2x(1).png" alt=""> -->
            <span>上一篇：业务流程管理系统</span>
          </div>
          <div class="div9_wenzi">
            <span>下一篇：科技发展</span>
            <!-- <img src="./img/公共-更多2@2x.png" alt=""> -->
          </div>
        </div>
      </div>
     
    </div>
     <!-- <saddsac/> -->
  </div>
</template>

<script>
// import saddsac from"../zpxi/sqziwei.vue"
export default {
components:{
  // saddsac,
}
};
</script>

<style  scoped>

.div9_wenxi {
  margin-top: 30px;
}
.div9_wenzi {
  margin-left: 837px;
  margin-top: 30px;
}
.div9 img {
  width: 16px;
  height: 16px;
}
.div9 {
  width: 1200px;
  height: 68px;
  background: #f8f8f8;
  margin-top: 52px;
  display: flex;
}
.div8 .p2 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 32px;
  margin: 0;
}
.div8 .p1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 28px;
}
.div8 {
  margin-top: 60px;
}
.div7_wenzi {
  width: 64px;
  height: 22px;
  margin-left: 48px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
}
.div7 {
  width: 160px;
  height: 48px;
  background: #23337f;
  border-radius: 4px;
  margin-left: 630px;
}

.div6 .span6 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
  margin-left: 158px;
}
.div6 .span5 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}

.div5 .span4 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
  margin-left: 120px;
}
.div5 .span3 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}
.div5 {
  margin-top: 30px;
  display: flex;
}
.div4 .span2 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-top: 80px;
}
.div4 .span1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 33px;
  margin-top: 80px;
}
.div4 {
  display: flex;
  justify-content: space-between;
}
.div3 {
  width: 1200px;
  height: 778px;
  background: #ffffff;
  /* border: 1px solid red; */
}
.div2 {
  width: 1200px;
  height: 883px;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.div1 {
  width: 1920px;
  /* height: 1774px; */
  /* border: 1px solid red; */
  margin-top: 45px;
}
</style>
