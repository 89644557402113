<template>
  <div class="all">
    <Header></Header>
    <div class="part2">
      <div class="part2-text1">招聘信息</div>
      <div class="part2-text2">欢迎加入我们</div>
    </div>
    <div class="part3">
      <div>当前位置：首页 > 新闻中心 > 企业新闻</div>
    </div>
    <div class="part4">
      <div class="part4-div1" v-for="index in 10" :key="index">
        <div class="part4-div1-text1">
          <span class="part4-div1-text1-span1">行政运营</span>
          <span class="part4-div1-text1-span2">面议</span>
        </div>
        <div class="part4-div1-text2">
          <div class="part4-div1-text2-div1">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">学历要求:</p>
              <p class="part4-div1-text2-div1-p2">工作地点:</p>
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">大专以上</p>
              <p class="part4-div1-text2-div1-p4">河南郑州</p>
            </div>
          </div>
          <div class="part4-div1-text2-div2">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">工作经验:</p>
              <p class="part4-div1-text2-div1-p2">截止时间:</p>
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">两年以上</p>
              <p class="part4-div1-text2-div1-p4">2019-12-30</p>
            </div>
          </div>
          <div class="part4-div1-text2-div3">
            <div class="part4-div1-text2-div1-text1">
              <p class="part4-div1-text2-div1-p1">招聘人数:</p>
              <!-- <p class="part4-div1-text2-div1-p2">工作地点:</p> -->
            </div>
            <div class="part4-div1-text2-div1-text2">
              <p class="part4-div1-text2-div1-p3">2人</p>
              <!-- <p class="part4-div1-text2-div1-p4">河南郑州</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="part5">
      <div class="part5-text1">申请职位</div>
      <div class="part5-div2">
        <div class="part4-div2-div1"></div>
        <div class="part4-div2-div2"></div>
      </div>
    </div> -->
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style5.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
export default {
  components: {
    Header,
    Nav,
  },
};
</script>