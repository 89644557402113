<template>
  <div class="all">
    <Header></Header>
    <div class="part2">
      <div class="part2-text1">
        集团产业
        <div style="display: inline-block">.</div>
        金融板块
      </div>
      <!-- <div class="part2-text2">欢迎加入我们</div> -->
    </div>
    <div class="part3">
      <div>当前位置：首页 > 集团概况</div>
    </div>
    <div class="part4">
      <div class="part-text">金融板块</div>
      <div class="part4-div2">
        <div class="part4-div2-text1">
          金融板块坚持“协同合作、助推发展、服务社会”的经营理念和“专业化、职业化、精英化、精细化”的人才管理理念，建立规范的投资操作流程和完善的风险控制体系，吸纳了一支具有金融、管理、法律、财务等经济领域的精英人才团队，凭借其丰富的行业经验和敏锐的行业洞察力，深度运营旗下各类金融产品，为广大客户提供全面、丰富、创新和个性化的金融产品与服务，制定全方位、专业的金融解决方案。
          金融板块借力雄厚的资金实力、丰富的政企资源以及各领域众多资深合作伙伴，主要从事融资租赁、信托、产业基金、债权融资等业务。目前，通过融资规划及联合租赁等方式促成的合作已累计超过150亿元，受客户和资方委托管理标的物及资产达170亿元。保障所有资金落地、资产管理安全使我们在业界赢得了良好的信誉口碑。
        </div>
        <div class="part4-div2-img">
          <img src="../assets/homepage/矩形（8）.png" alt="" />
        </div>
      </div>
    </div>
    <div class="about">
      <div class="part5">
        <div class="part5-div1">
          <img src="../assets/homepage/编组 2.png" class="part5-div1-img" />
          <div class="part5-div1-text">融资租赁</div>
        </div>
        <div class="part5-div2">
          <div class="part5-div2-text1">
            金融板块依托强大的产业背景、雄厚的资金实力、丰富的政企资源以及各领域众多资深合作伙伴，借助灵活的资本运作方式以及集团采购、整体打包的资源优势，通过融资租赁、直接租赁、售后回租、厂商租赁、风险租赁等业务方式，为政企客户提供全方位、专业化、一站式的金融服务。
          </div>
          <div class="part5-div2-text2">
            <p>01</p>
            <div>
              根据承租人的要求，向承租人指定的供货商购买指定的设备，并将该设备的占有、使用和收益权转让给承租人
            </div>
            <p>02</p>
            <div>
              承租人分期支付租金，租金偿还完毕后按合同类型确认设备所有权归属的一种以租代买的融资方式
            </div>
            <p>03</p>
            <div>业务种类：直接租赁、售后回租、厂商租赁、风险租赁</div>
          </div>
        </div>
      </div>
      <div class="part6" v-for="item in ObjectList" :key="item.img1">
        <div class="part6-div1">
          <img :src="item.img1" class="part6-div1-img1" />
          <img :src="item.img2" class="part6-div1-img2" />
          <div class="part6-div1-text">{{ item.title }}</div>
        </div>
        <div class="part6-div2">
          {{ item.text }}
          <br />
          <span>{{ item.text1 }}</span>
        </div>
      </div>
    </div>
    <Nav></Nav>
  </div>
</template>
<style scoped>
@import url("../assets/style6.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from '../components/nav1/index2.vue';
export default {
  components: {
     Header,
    Nav,

  },
  data() {
    return {
      ObjectList: [
        {
          img1: require("../assets/homepage/位图(13).png"),
          img2: require("../assets/homepage/编组 (3).png"),
          title: "信托",
          text: "信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。",
          text1:
            "金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。",
        },
        {
          img1: require("../assets/homepage/位图(14).png"),
          img2: require("../assets/homepage/编组 （5).png"),
          title: "产业基金",
          text: "产业投资基金是指一种对未上市企业进行股权投资和提供经营管理服务的利益共享、风险共担的集合投资制度。金融板块以医疗服务类产业为核心，从事创业投资、企业重组投资和基础设施投资等实业投资，并以产业基金投资并孵化相关业务条线，涉及医疗健康、公用事业、教育旅游、交通物流、文化产业等众多领域，不断服务国家战略发展。",
        },
        {
          img1: require("../assets/homepage/位图(15).png"),
          img2: require("../assets/homepage/编组（4）.png"),
          title: "债权融资",
          text: "债权融资计划是指融资人向具备相应风险识别和承担能力的合格投资者，通过发行载体，非公开挂牌募集资金，并以基础资产产生的现金流作为偿付支持的融资方式。金融板块通过创新咨询类业务，为政企客户及其他投资人提供债权融资项目咨询服务和金融解决方案，帮助客户更加灵活地筹集资金，解决发展中存在的问题，支持各类客户实现多元化的发展。",
        },
      ],
    };
  },
};
</script>
